import * as React from "react"
import {navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {isLoggedIn} from "../utils/auth.service";
import LoadingSpinner from "../components/loading-spinner";
import authHeader from "../utils/auth-header";
import AttractedCustomersList from "../components/attracted-customers/list";


const AttractedCustomers = () => {

  const [loading,setLoading] = useState(true);
  const [attractedCustomers,setAttractedCustomers] = useState([]);

  useEffect(() => {

    if(!isLoggedIn()){
      return navigate('/');
    }

    loadAttractedCustomers();


  }, [])



  const loadAttractedCustomers = () => {

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };


    fetch(process.env.WASHCAR_API_URL + "/partners/my-attracted-clients", requestOptions)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
            setAttractedCustomers(resultData.data.attracted_customers);
            setLoading(false);
        }) //
  }

  return (
    <Layout>
      <Seo title="Piesaistītie klienti" />
      <h1 className="title">Piesaistītie klienti</h1>
        <div className={'notification'}>Šeit redzami visi WashCar klienti, kuri ir ievadījuši jūsu promo kodu, tajā skaitā arī tie, kuri vēl nav izdarījuši nevienu pasūtījumu.</div>
      {loading ? <div className="pos-center"><LoadingSpinner /></div>  :<AttractedCustomersList attractedCustomers={attractedCustomers} />}
    </Layout>
  )
}

export default AttractedCustomers

