import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone} from '@fortawesome/free-solid-svg-icons'

const AttractedCustomersList = (props) => {

  return (

      props.attractedCustomers.length === 0 ? <div className={'notification'}>Vēl nav neviena piesaistītā klienta.</div> :

      <div className={'box'}>
        <div className="list">
            {  props.attractedCustomers.map(attractedCustomer => (
                <div key={attractedCustomer.phone_with_code} className="list-item">
                    <div className="list-item-content">
                        <div className="list-item-title"><span className="icon  mr-1"><FontAwesomeIcon icon={faPhone}/></span><b>{attractedCustomer.phone_with_code}</b></div>
                        <div className="list-item-description">
                            <div className="tag is-rounded">Kods ievadīts: <b>{attractedCustomer.attracted_at}</b></div>

                        </div>
                    </div>
                </div>
            ))}
        </div>
      </div>
  );
};

export default AttractedCustomersList;

